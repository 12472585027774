import { NgFor, NgIf } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MerchantInsightsModel } from 'src/app/core/domain/merchant-insights.model';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetInsightsDataUseCase } from 'src/app/core/usecases/merchant-insights/get-insights-data.usecase';
import { GetInsightsUrlUseCase } from 'src/app/core/usecases/merchant-insights/get-insights-url.usecase';
import { CheckUserFeatureExistsUseCase } from 'src/app/core/usecases/user/check-user-feature-exists.usecase';
import { BREAK_EVEN_AND_AVG_PROFIT_DATA } from 'src/app/data/repositories/merchant-insights/merchant-insights-data-source';
import { cesLocationNotifierUtility } from '../shared/utilities/ces-location-notifier.utility';

@Component({
  selector: 'app-merchant-insights',
  standalone: true,
  imports: [TranslateModule, MatTabsModule, MatIconModule, NgFor, NgIf],
  templateUrl: './merchant-insights.component.html',
  styleUrls: ['./merchant-insights.component.scss'],
})
export class MerchantInsightsComponent implements OnInit {
  public dataInsights: MerchantInsightsModel[];

  public insightUrl: SafeResourceUrl;

  public selectedIndex = 0;

  public isProfitTabAdded = false;

  public infoDefinitionsOne = [
    {
      title: 'INSIGHTS.INFO.CONFIRMED_ORDERS_TITLE',
      text: 'INSIGHTS.INFO.CONFIRMED_ORDERS_TEXT',
    },
    {
      title: 'INSIGHTS.INFO.CONFIRMATION_RATE_TITLE',
      text: 'INSIGHTS.INFO.CONFIRMATION_RATE_TEXT',
    },
    {
      title: 'INSIGHTS.INFO.RETURNED_ORDERS_TITLE',
      text: 'INSIGHTS.INFO.RETURNED_ORDERS_TEXT',
    },
    {
      title: 'INSIGHTS.INFO.IN_PROGRESS_RATE_TITLE',
      text: 'INSIGHTS.INFO.IN_PROGRESS_RATE_TEXT',
    },
  ];

  public infoDefinitionsTwo = [
    {
      title: 'INSIGHTS.INFO.IN_PROGRESS_ORDERS_TITLE',
      text: 'INSIGHTS.INFO.IN_PROGRESS_ORDERS_TEXT',
    },
    {
      title: 'INSIGHTS.INFO.DELIVERY_RATE_WITH_RETURNS_TITLE',
      text: 'INSIGHTS.INFO.DELIVERY_RATE_WITH_RETURNS_TEXT',
    },
    {
      title: 'INSIGHTS.INFO.DELIVERY_RATE_WITHOUT_RETURNS_TITLE',
      text: 'INSIGHTS.INFO.DELIVERY_RATE_WITHOUT_RETURNS_TEXT',
    },
    {
      title: 'INSIGHTS.INFO.DELIVERED_ORDERS_TITLE',
      text: 'INSIGHTS.INFO.DELIVERED_ORDERS_TEXT',
    },
  ];

  public breakEvenDefinitionsOne = [
    {
      title: 'INSIGHTS.BREAK_EVEN.TOTAL_PROFIT_TITLE',
      text: 'INSIGHTS.BREAK_EVEN.TOTAL_PROFIT_TEXT',
    },
    {
      title: 'INSIGHTS.BREAK_EVEN.NET_DELIVERED_PROFIT_TITLE',
      text: 'INSIGHTS.BREAK_EVEN.NET_DELIVERED_PROFIT_TEXT',
    },
    {
      title: 'INSIGHTS.BREAK_EVEN.PROFIT_PER_ORDER_TITLE',
      text: 'INSIGHTS.BREAK_EVEN.PROFIT_PER_ORDER_TEXT',
    },
    {
      title: 'INSIGHTS.BREAK_EVEN.PROFIT_NDR_TITLE',
      text: 'INSIGHTS.BREAK_EVEN.PROFIT_NDR_TEXT',
    },
  ];

  public breakEvenDefinitionsTwo = [
    {
      title: 'INSIGHTS.BREAK_EVEN.BREAK_EVENT_TITLE',
      text: 'INSIGHTS.BREAK_EVEN.BREAK_EVENT_TEXT',
    },
    {
      title: 'INSIGHTS.BREAK_EVEN.BREAK_EVEN_CPA_TITLE',
      text: 'INSIGHTS.BREAK_EVEN.BREAK_EVEN_CPA_TEXT',
    },
    {
      title: 'INSIGHTS.BREAK_EVEN.AVG_PROFIT_NDR_TITLE',
      text: 'INSIGHTS.BREAK_EVEN.AVG_PROFIT_NDR_TEXT',
    },
    {
      title: 'INSIGHTS.BREAK_EVEN.AVG_BREAK_EVEN_CPA_TITLE',
      text: 'INSIGHTS.BREAK_EVEN.AVG_BREAK_EVEN_CPA_TEXT',
    },
  ];

  public failedConfirmationReasonsDefinitions = [
    {
      title: 'INSIGHTS.FAILED_CONFIRMED_REASONS.NUMBER_OF_REJECTED_PIECES_TITLE',
      text: 'INSIGHTS.FAILED_CONFIRMED_REASONS.NUMBER_OF_REJECTED_PIECES_TEXT',
    },
    {
      title: 'INSIGHTS.FAILED_CONFIRMED_REASONS.PRODUCT_REJECTION_RATE_TITLE',
      text: 'INSIGHTS.FAILED_CONFIRMED_REASONS.PRODUCT_REJECTION_RATE_TEXT',
    },
    {
      title: 'INSIGHTS.FAILED_CONFIRMED_REASONS.AVERAGE_RATE_TITLE',
      text: 'INSIGHTS.FAILED_CONFIRMED_REASONS.AVERAGE_RATE_TEXT',
    },
  ];

  private _getInsightsDataUseCase = inject(GetInsightsDataUseCase);

  private _getInsightsUrlUseCase = inject(GetInsightsUrlUseCase);

  private _logMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  private _translateService = inject(TranslateService);

  private _sanitizer = inject(DomSanitizer);

  private _cesLocationNotifierUtility = cesLocationNotifierUtility();

  constructor(private _checkUserFeatureExistsUseCase: CheckUserFeatureExistsUseCase) {}

  ngOnInit(): void {
    this._cesLocationNotifierUtility({ action: 'write', location: 'insights' });
    this.getInsightsData();
  }

  getInsightsData(): void {
    this._getInsightsDataUseCase.execute().subscribe((data) => {
      this.dataInsights = data;
      const breakEvenUserFeature = 'break_even_merchant_insights';
      this.isProfitTabAdded = this.dataInsights
        .map((dataInsight) => dataInsight.name)
        .includes('INSIGHTS.PROFITS');
      if (
        this._checkUserFeatureExistsUseCase.execute(breakEvenUserFeature) &&
        !this.isProfitTabAdded
      ) {
        this.dataInsights.push(BREAK_EVEN_AND_AVG_PROFIT_DATA);
      }
      this.tabClicked(this.dataInsights[0]);
    });
  }

  tabClicked(insight: MerchantInsightsModel): void {
    this.selectedIndex = insight.index;
    const data = {
      type: insight.type,
      id: insight.id,
    };
    this._logMixpanelEventUseCase.execute({
      eventName: 'merchant_insights_clicked',
      payload: {
        insightNumber: insight.id,
        insightType: insight.type,
        insightName: this._translateService.instant(insight.name),
      },
    });
    this._getInsightsUrlUseCase.execute(data).subscribe(({ url }) => {
      this.insightUrl = this._sanitizer.bypassSecurityTrustResourceUrl(url);
    });
  }
}
