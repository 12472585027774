<div class="insights">
  <p class="heading2--extra-bold content-main-color">
    {{ 'INSIGHTS.HEADER' | translate }}
  </p>
  <p class="caption1--medium content-medium-color">
    {{ 'INSIGHTS.SUB_HEADER' | translate }}
  </p>
  <mat-tab-group animationDuration="0ms">
    <mat-tab *ngFor="let insight of dataInsights">
      <ng-template mat-tab-label>
        <div class="caption1--medium insights__tab" (click)="tabClicked(insight)">
          {{ insight.name | translate }}
          <span class="caption1--medium new-text" *ngIf="insight.new">{{
            'INSIGHTS.NEW' | translate
          }}</span>
        </div>
      </ng-template>
      <div class="insights__info-container" *ngIf="selectedIndex === 0">
        <p class="body2--medium content-medium-color">
          {{ 'INSIGHTS.DEFINITIONS' | translate }}
        </p>
        <div class="insights__info-container__row">
          <div class="insights__info-container__column">
            <p *ngFor="let info of infoDefinitionsOne" class="body2--bold content-primary-color">
              {{ info.title | translate }}:<span class="body2--bold content-main-color">
                {{ info.text | translate }}
              </span>
            </p>
          </div>
          <div class="separator"></div>
          <div class="insights__info-container__column">
            <p *ngFor="let info of infoDefinitionsTwo" class="body2--bold content-primary-color">
              {{ info.title | translate }}:<span class="body2--bold content-main-color">
                {{ info.text | translate }}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div class="insights__info-container" *ngIf="selectedIndex === 3">
        <p class="body2--medium content-medium-color">
          {{ 'INSIGHTS.DEFINITIONS' | translate }}
        </p>
        <div class="insights__info-container__row">
          <div class="insights__info-container__column">
            <p
              *ngFor="let info of breakEvenDefinitionsOne"
              class="body2--bold content-primary-color"
            >
              {{ info.title | translate }}:<span class="body2--bold content-main-color">
                {{ info.text | translate }}
              </span>
            </p>
          </div>
          <div class="separator"></div>
          <div class="insights__info-container__column">
            <p
              *ngFor="let info of breakEvenDefinitionsTwo"
              class="body2--bold content-primary-color"
            >
              {{ info.title | translate }}:<span class="body2--bold content-main-color">
                {{ info.text | translate }}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div class="insights__info-container" *ngIf="selectedIndex === 5">
        <p class="body2--medium content-medium-color">
          {{ 'INSIGHTS.DEFINITIONS' | translate }}
        </p>
        <div class="insights__info-container__row">
          <div class="insights__info-container__column">
            <p
              *ngFor="let info of failedConfirmationReasonsDefinitions"
              class="body2--bold content-primary-color"
            >
              {{ info.title | translate }}:<span class="body2--bold content-main-color">
                {{ info.text | translate }}
              </span>
            </p>
            <p class="body2--bold content-primary-color">
              {{ 'INSIGHTS.FAILED_CONFIRMED_REASONS.ALL' | translate }}
            </p>
          </div>
        </div>
      </div>
      <div class="insights__tab-content">
        <iframe
          *ngIf="insightUrl"
          [src]="insightUrl"
          frameborder="0"
          class="metabase-table"
          allowtransparency
        ></iframe>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
