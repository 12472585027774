import { NgModule } from '@angular/core';
import { provideRouter } from '@angular/router';
import { MerchantInsightsComponent } from './merchant-insights.component';

@NgModule({
  imports: [MerchantInsightsComponent],
  providers: [
    provideRouter([
      {
        path: '',
        component: MerchantInsightsComponent,
      },
    ]),
  ],
})
export class MerchantInsightsModule {}
